@use '~@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
@include mat.core();

// Typography
$custom-typography: mat.define-typography-config(
  $font-family: Montserrat,
  $headline: mat.define-typography-level(24px, 48px, 400),
  $body-1: mat.define-typography-level(16px, 24px, 400)
);
@include mat.all-component-typographies($custom-typography);

// Default colors
$my-app-primary: mat.define-palette(mat.$indigo-palette, 700, 100, 800);
$my-app-accent:  mat.define-palette(mat.$grey-palette, 700, 100, 800);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);
@include mat.all-component-themes($my-app-theme);

* {
    font-family: 'Montserrat', sans-serif;
}

html, body { 
    height: 100%;
    margin: 0;
}

:root {
    --primary-color: #35519E;
    --secondary-color: #15203F;
}

.success-snackbar {
    background-color: rgb(175, 255, 122);
    color: green !important;
    // .mat-simple-snackbar-action {
    //     color: rgb(0, 0, 92) !important;
    //  }
}

.danger-snackbar {
    background-color: rgb(255, 123, 123);
    color: rgb(102, 0, 0) !important;
    // .mat-simple-snackbar-action {
    //     color: rgb(0, 0, 92) !important;
    //  }
}

.default-snackbar {
    background-color: rgb(96, 160, 255);
    color: rgb(0, 19, 102) !important;
    .mat-simple-snackbar-action {
        color: white !important;
     }
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }
    .no-print { display: none !important;}
    .print { display: block !important; }
}

.mat-button-focus-overlay { background-color: transparent!important; }